import {
  LazyLoadingHandler_default
} from "./chunk.YPEUFH4Z.js";
import {
  Linkable,
  jbLinkClickedEventToJbTrackingEvent
} from "./chunk.VPBTB6HE.js";
import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-first-level-teaser-item/jb-first-level-teaser-item.ts
import { html, LitElement, unsafeCSS } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

// src/components/jb-first-level-teaser-item/jb-first-level-teaser-item.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_first_level_teaser_item_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
a {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-weight: 900;
}

h2 {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 300;
}

h2 {
  font-size: 26px;
  line-height: 35px;
}
@media (min-width: 1281px) {
  h2 {
    font-size: 31px;
    line-height: 42px;
  }
}

a {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 11px;
  letter-spacing: 2px;
  line-height: 16px;
  text-decoration: none;
  text-transform: uppercase;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

:host {
  transition: all 0.3s ease-out 0s;
  width: 100%;
  --player-size: 200px;
}
@media (min-width: 1281px) {
  :host {
    --player-size: 250px;
  }
}

h2 {
  position: absolute;
  text-align: center;
  top: 175px;
  transition: all 0.3s ease-out 0s;
  width: 100%;
}
@media (min-width: 600px) {
  h2 {
    top: calc(var(--player-size) / 2 - 35px);
    width: auto;
  }
}
@media (min-width: 600px) {
  .left h2 {
    text-align: right;
  }
}
@media (min-width: 600px) {
  .right h2 {
    left: 50%;
    right: 8.33%;
    text-align: right;
  }
}
@media (min-width: 1281px) {
  .right h2 {
    left: 50%;
    right: calc(8.33% + 20px);
    text-align: right;
  }
}
@media (min-width: 1437px) {
  .right h2 {
    right: calc(8.33% - 20px);
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  height: 297px;
  justify-content: flex-end;
  position: relative;
}
@media (min-width: 600px) {
  .container {
    height: 244px;
    justify-content: flex-start;
  }
}
.container.right {
  justify-content: flex-start;
}
@media (min-width: 600px) {
  .container.right {
    justify-content: flex-end;
  }
}

.lottie-wrapper {
  border-radius: 50%;
  height: var(--player-size);
  position: absolute;
  right: 0;
  transition: all 0.3s ease-out 0s;
  width: var(--player-size);
}
@media (min-width: 1281px) {
  .lottie-wrapper {
    height: var(--player-size);
    width: var(--player-size);
  }
}
.right .lottie-wrapper {
  left: 0;
  right: auto;
}

.background {
  background-color: #fff;
  border-radius: 50%;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: scale(1);
  transition: transform 0.3s ease-in 0s;
  width: 100%;
}
.background.hover {
  transform: scale(1.2);
}

lottie-player {
  border-radius: 50%;
  height: 100%;
  isolation: isolate;
  left: 0;
  overflow: hidden;
  padding: var(--lottie-player-padding, 0);
  position: absolute;
  top: 0;
  width: 100%;
}

.button {
  --button-height: 36px;
  cursor: pointer;
  display: inline-block;
  font-size: 0;
  height: var(--button-height);
  position: absolute;
  top: 221px;
  transition: all 0.3s ease-out 0s;
  user-select: none;
}
@media (min-width: 600px) {
  .button {
    top: calc(var(--player-size) * 0.71 - var(--button-height));
  }
}
.button::before {
  border-left: 1px solid #141e55;
  bottom: 0;
  content: "";
  height: 66%;
  left: 0;
  position: absolute;
  right: auto;
  transition: height 0.2s ease-out 0.1s;
  width: 1px;
}
.button::after {
  border-bottom: 1px solid #141e55;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: auto;
  transition: all 0.3s ease-out 0s;
  width: 50%;
}
@media (min-width: 600px) {
  .button::after {
    width: 100%;
  }
}
.button:hover::before {
  height: 0;
  transition: height 0.2s ease-out 0.1s;
}
.button:hover::after {
  transition: all 0.3s ease-out 0s;
  width: calc(100% + 20px);
}
@media all and (-ms-high-contrast: none) {
  .button:hover::after {
    width: 100%;
  }
}
@supports (-ms-ime-align: auto) {
  .button:hover::after {
    width: 100%;
  }
}
.left .button {
  right: 30%;
}
@media (min-width: 600px) {
  .left .button {
    left: 8.33%;
    right: calc(var(--player-size) * 0.9);
  }
}
@media (min-width: 1281px) {
  .left .button {
    left: calc(8.33% + 40px);
  }
}
.right .button {
  left: 30%;
}
@media (min-width: 600px) {
  .right .button {
    left: calc(var(--player-size) * 0.9);
    right: 8.33%;
    text-align: right;
  }
  .right .button::before, .right .button::after {
    left: auto;
    right: 0;
  }
}
@media (min-width: 1281px) {
  .right .button {
    right: calc(8.33% + 40px);
  }
}
@media (min-width: 1437px) {
  .right .button {
    right: 8.33%;
  }
}

.circle {
  border: 1px solid #141e55;
  border-radius: 100%;
  bottom: -3.4px;
  display: none;
  height: 8px;
  position: absolute;
  transition: all 0.3s ease-out 0s;
  width: 8px;
}
@media (min-width: 600px) {
  .circle {
    display: inline;
  }
}
.left .circle {
  right: -8px;
}
.right .circle {
  left: -8px;
}
.left .button:hover .circle {
  right: -28px;
}
.right .button:hover .circle {
  left: -28px;
}

a {
  color: #141e55;
  display: inline-block;
  outline: none;
  padding: 10px 16px;
  transform: translate(0, 0);
  transition: transform 0.3s ease-out 0s;
}
.button:hover a {
  transform: translate(20px, 0);
}
@media (min-width: 600px) {
  .right .button:hover a {
    transform: translate(-20px, 0);
  }
}`;

// src/components/jb-first-level-teaser-item/jb-first-level-teaser-item.ts
var JbFirstLevelTeaserItem = class extends Linkable(LitElement) {
  constructor() {
    super(...arguments);
    this._hover = false;
  }
  firstUpdated(_changedProperties) {
    super.firstUpdated(_changedProperties);
    LazyLoadingHandler_default.observe(
      this,
      async () => {
        await import("@lottiefiles/lottie-player");
        this._lottiePlayerStart.load(this.lottieSourceStart);
        this._lottiePlayerNeutral.load(this.lottieSourceNeutral);
        this._lottiePlayerHover.load(this.lottieSourceHover);
        this._lottiePlayerStart.addEventListener("complete", () => {
          if (!this._hover) {
            this._showNeutral();
            this._lottiePlayerNeutral.play();
          }
        });
        this._lottiePlayerHover.addEventListener("complete", () => {
          if (!this._hover) {
            this._showNeutral();
            this._lottiePlayerNeutral.stop();
            this._lottiePlayerNeutral.play();
          }
        });
        LazyLoadingHandler_default.observe(
          this._lottiePlayer,
          () => {
            this._lottiePlayerStart.play();
          },
          "fullInViewport"
        );
      },
      "approachingViewport"
    );
    const resizeObserver = new ResizeObserver((entries) => {
      this._titleLineHeight = parseInt(getComputedStyle(this._currentTitle).lineHeight, 10);
      const rect = entries[0];
      const height = Math.floor(rect.contentRect.height);
      if (height !== this._titleLineHeight) {
        this._currentTitle.style.transform = `translateY(-${height - this._titleLineHeight}px)`;
      } else {
        this._currentTitle.style.transform = `translateY(0)`;
      }
    });
    resizeObserver.observe(this._currentTitle);
  }
  _handleMouseLeave() {
    this._hover = false;
    this._showHover();
    this._lottiePlayerHover.setDirection(-1);
    this._lottiePlayerHover.play();
  }
  _handleMouseOver() {
    this._hover = true;
    this._showHover();
    this._lottiePlayerHover.setDirection(1);
    this._lottiePlayerHover.play();
  }
  _showHover() {
    this._lottiePlayerStart.style.display = "none";
    this._lottiePlayerNeutral.style.display = "none";
    this._lottiePlayerHover.style.display = "block";
  }
  _showNeutral() {
    this._lottiePlayerStart.style.display = "none";
    this._lottiePlayerHover.style.display = "none";
    this._lottiePlayerNeutral.style.display = "block";
  }
  _tracking(e) {
    const jbTrackingEvent = jbLinkClickedEventToJbTrackingEvent(e, {
      component_name: "first-level-teaser",
      interaction_text: e.detail.linkTag.innerText
    });
    if (jbTrackingEvent) {
      this.dispatchEvent(jbTrackingEvent);
    }
  }
  render() {
    return html`
      <div
        class="${classMap({
      left: this.position === "first" || this.position === "third",
      right: this.position === "second",
      container: true
    })}"
      >
        <div
          class="lottie-wrapper"
          @mouseover="${this._handleMouseOver}"
          @mouseleave="${this._handleMouseLeave}"
        >
          <div
            class="${classMap({
      hover: this._hover,
      background: true
    })}"
          ></div>
          <lottie-player
            state="start"
            mode="normal"
          ></lottie-player>
          <lottie-player
            style="display: none"
            state="hover"
            mode="normal"
          ></lottie-player>
          <lottie-player
            style="display: none"
            state="neutral"
            mode="normal"
            loop="true"
          ></lottie-player>
        </div>
        <h2>${this.title}</h2>
        <div
          class="button"
          @mouseover="${this._handleMouseOver}"
          @mouseleave="${this._handleMouseLeave}"
        >
          <span class="circle"></span>
          <a
            data-linkable
            @jb-link:clicked=${this._tracking}
            >${this.buttonText}</a
          >
        </div>
      </div>
    `;
  }
};
JbFirstLevelTeaserItem.styles = unsafeCSS(jb_first_level_teaser_item_default);
__decorateClass([
  property()
], JbFirstLevelTeaserItem.prototype, "buttonText", 2);
__decorateClass([
  property()
], JbFirstLevelTeaserItem.prototype, "lottieSourceHover", 2);
__decorateClass([
  property()
], JbFirstLevelTeaserItem.prototype, "lottieSourceNeutral", 2);
__decorateClass([
  property()
], JbFirstLevelTeaserItem.prototype, "lottieSourceStart", 2);
__decorateClass([
  property()
], JbFirstLevelTeaserItem.prototype, "position", 2);
__decorateClass([
  property()
], JbFirstLevelTeaserItem.prototype, "title", 2);
__decorateClass([
  query("h2")
], JbFirstLevelTeaserItem.prototype, "_currentTitle", 2);
__decorateClass([
  state()
], JbFirstLevelTeaserItem.prototype, "_hover", 2);
__decorateClass([
  query("a")
], JbFirstLevelTeaserItem.prototype, "_link", 2);
__decorateClass([
  query("lottie-player")
], JbFirstLevelTeaserItem.prototype, "_lottiePlayer", 2);
__decorateClass([
  query("lottie-player[state=hover]")
], JbFirstLevelTeaserItem.prototype, "_lottiePlayerHover", 2);
__decorateClass([
  query("lottie-player[state=neutral]")
], JbFirstLevelTeaserItem.prototype, "_lottiePlayerNeutral", 2);
__decorateClass([
  query("lottie-player[state=start]")
], JbFirstLevelTeaserItem.prototype, "_lottiePlayerStart", 2);
JbFirstLevelTeaserItem = __decorateClass([
  customElement("jb-first-level-teaser-item")
], JbFirstLevelTeaserItem);

export {
  JbFirstLevelTeaserItem
};
